import React from 'react';
import { Box } from "@chakra-ui/react";

import ReportBody from '../../components/report/dartcustomreportbody';
import ReportFooter from '../../components/report/reportfooter';

const ComplianceReport = () => {
  const CompanyName = "Dart";
  const CompanyEmail = "security@itsdart.com";
  const AccentColor = '#446bf8';
  const ComplianceFrameworks = ["SOC2Ongoing"];
  const PrivacyPolicyURL = "https://app.itsdart.com/legal/privacy-policy";

  return (
    <Box className="figtree" w="100%" bg="#F0F0F0">
        <ReportBody CompanyName={CompanyName} CompanyEmail={CompanyEmail} AccentColor={AccentColor} ComplianceFrameworks={ComplianceFrameworks} PrivacyPolicyURL={PrivacyPolicyURL}/>
        <ReportFooter AccentColor={AccentColor}/>
    </Box>
  );
};

export default ComplianceReport;