import React, { useEffect, useState } from 'react';
import { Box } from "@chakra-ui/react";

import ReportBody from '../../components/report/reportbody';
import ReportFooter from '../../components/report/reportfooter';

const ComplianceReport = () => {
  const CompanyName = "Argovox";
  const CompanyEmail = "founders@argovox.com";
  const AccentColor = '#f5ec42';
  const ComplianceFrameworks = ["HIPAA"];
  const PrivacyPolicyURL = "https://app.termly.io/policy-viewer/policy.html?policyUUID=79ba4e2f-4827-475b-b304-b9c7d7b86f83";

  return (
    <Box className="figtree" w="100%" bg="#F0F0F0">
        <ReportBody CompanyName={CompanyName} CompanyEmail={CompanyEmail} AccentColor={AccentColor} ComplianceFrameworks={ComplianceFrameworks} PrivacyPolicyURL={PrivacyPolicyURL}/>
        <ReportFooter AccentColor={AccentColor}/>
    </Box>
  );
};

export default ComplianceReport;

