import React, { useState, useEffect } from 'react';
import { Box, Text, HStack, SimpleGrid, VStack, Collapse, Button, Icon, Tr, Td, Tbody, Stack, Image, Table } from "@chakra-ui/react";
import { EmailIcon, LinkIcon, CheckCircleIcon } from '@chakra-ui/icons';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import RegularText from '../text/regulartext.js';
import TitleText from '../text/titletext.js';
import WhiteSubtitleText from '../text/whitesubtitletext.js';
import SOC2Ongoing from '../../assets/Badges/SOC2OngoingBadge.png'

import BlandAI from '../../assets/CustomerLogos/Bland.png'
import Automat from '../../assets/CustomerLogos/Automat.png'
import RythmHealth from '../../assets/CustomerLogos/RythmHealth.png'
import CloudCruise from '../../assets/CustomerLogos/CloudCruise.png'
import Grove from '../../assets/CustomerLogos/Grove.png'
import Toma from '../../assets/CustomerLogos/Toma.png'
import CureAI from '../../assets/CustomerLogos/CureAI.png'
import DocThumbnail from '../../assets/greendocthumbnail.png'
import HIPAA from '../../assets/Badges/HIPAABadge.png'
import SOC2Type1 from '../../assets/Badges/SOC2Type1Badge.png'
import ThumosCare from '../../assets/CustomerLogos/ThumosCare.png'
import Thoughtly from '../../assets/CustomerLogos/Thoughtly.png'
import Fella from '../../assets/CustomerLogos/Fella.png'
import Argovox from '../../assets/CustomerLogos/Argovox.png'
import PrimaryHealth from '../../assets/CustomerLogos/PrimaryHealth.png'
import Twine from '../../assets/CustomerLogos/Twine.png'
import HammingAI from '../../assets/CustomerLogos/HammingAI.png'
import Dart from '../../assets/CustomerLogos/Dart.png'

const ComplianceCompanyHeader = ({ CompanyName, CompanyEmail, AccentColor, ComplianceFrameworks, PrivacyPolicyURL }) => {

const sectionControls = {
    "Administrative": [
        "Security reviews on 3rd-party tools",
        "Company policies",
        "Disaster recovery plan",
      "Background checks",
      "Employee onboarding checklist",
      "Employee offboarding checklist",
      "Security training",
      "Whistleblower procedure",
      "Performance evaluations",
      "Security task tracker",
      "Incident response plan",
      "Company inventory list",
      "Risk assessment",
      "Tabletop disaster recovery exercise"
    ],
    "Technical": [
      "Encryption in transit and at rest",
      "Logging/monitoring",
      "Database backups",
      "Access request tickets",
      "Segregated development and production environment",
      "Secondary review on code changes",
      "Codebase version control",
      "MFA on accounts",
      "Password manager",
      "Hard drive encryption",
      "Anti-malware settings",
      "Firewalls",
      "Screen lock",
      "Multiple availability zones",
      "Unused root infrastructure account",
      "Infrastructure security scanning"
    ],
  }




const legalPolicies = {
    "Legal policies": [
        "Designation of HIPAA Security Officer Policy",
        "Sanctioning of Employees, Agents and Contractors Policy",
        "Media Re-Use Policy",
        "Information System Activity Review Policy",
        "Workstation Use Policy",
        "Audit Controls Policy",
        "Security Risk Analysis Policy",
        "Security Risk Management Policy",
        "Response and Reporting of Security Incidents Involving PII Policy",
        "Data Backup Plan Policy",
        "Business Continuity and Disaster Recovery Policy",
        "Emergency Access and Emergency Mode Operation Policy",
        "Evaluation of HIPAA Policies",
        "Disposal Policy",
        "Person and Entity Authentication Policy",
        "Transmission Security Policy",
        "Workforce Security Policy",
        "Security Awareness and Training Policy",
        "Integrity Controls Policy",
        "Unique User Identification Policy",
        "Facility Access Controls Policy",
        "Workstation Security Policy",
        "Information Access Management Policy",
        "Compliance with Required and Addressable Standards Policy",
        "Acknowledgment of Receipt and Confidentiality Agreement",
        "Code Of Conduct"
    ],
  }


const [show, setShow] = useState({});

  const companyImages = {
    'Automat': Automat,
    'Bland AI': BlandAI,
    'Rythm Health': RythmHealth,
    'CloudCruise': CloudCruise,
    'Grove': Grove,
    'Toma': Toma,
    'CureAI': CureAI,
    'Thumos Care': ThumosCare,
    'Thoughtly': Thoughtly,
    'Fella': Fella,
    'Argovox': Argovox,
    'Primary Health': PrimaryHealth,
    'Twine': Twine,
    'Hamming AI': HammingAI,
    'Dart': Dart,
  };

  const complianceImages = {
    'HIPAA': HIPAA,
    'SOC2Type1': SOC2Type1,
    // 'SOC2Type2': SOC2Type2,
    'SOC2Ongoing': SOC2Ongoing,
  };

  return (
    <>
        <style>
        {`
            @keyframes flashing {
            0%, 100% { background-color: #63FDAC; opacity: 1; }
            50% { background-color: #63FDAC; opacity: 0; }
            }
        `}
        </style>
        
        <Box bg='#101012' pb='3rem'>
            <Box bg={AccentColor} pt='6rem'>
                <Box bg="#191919" borderTopRadius='lg' borderBottomRadius='0' h="auto" ml={['1rem', '2rem', '10rem']} mr={['1rem', '2rem', '10rem']} pl={['1rem', '2rem', '4rem']} pr={['1rem', '2rem', '4rem']} pt={['2rem', '3rem', '4rem']} pb='2rem' boxShadow='md'>
                    <Stack direction={{ base: 'column', md: 'row' }} align="start">
                    <Image 
                        src={companyImages[CompanyName]}
                        width={{ base: '120px', sm: '150px', md: '200px' }} 
                        height="auto" 
                        mr="3%"
                        alignSelf={{ md: 'flex-start' }}
                        borderRadius="8px"
                        />
                    <VStack align="left">
                    <TitleText fontSize="2xl" color="#353535" fontWeight='600' mb='1rem'>{CompanyName} Compliance Report</TitleText>
                    <Box p={1} w='fit-content' borderRadius='full' borderColor='#272729' borderWidth='1px'>
                        <HStack spacing={2} ml='5px' mr='5px'>
                            <Box w="8px" h="8px" borderRadius="full" style={{ animation: 'flashing 2s infinite' }}></Box>
                            <RegularText>Last updated {new Date().toLocaleDateString()}</RegularText>
                        </HStack>
                    </Box>
                    <RegularText sx={{mb:2, mt:4}}>At {CompanyName}, we actively monitor our security posture to ensure client and company data is protected. We follow compliance standards and industry best practices, which the following page serves to overview.</RegularText>
                        <HStack spacing={5}>
                        {PrivacyPolicyURL && (
                            <HStack>
                            <Icon as={LinkIcon} w={4} h={4} color="#353535" />
                            <RegularText>
                                <a href={PrivacyPolicyURL} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: AccentColor }}>Privacy Policy</a>
                            </RegularText>
                            </HStack>
                        )}
                        <HStack>
                            <Icon as={EmailIcon} w={4} h={4} color="#353535" />
                            <RegularText>
                            <a href={`mailto:${CompanyEmail}`} style={{ textDecoration: "none", color: AccentColor }}>{CompanyEmail}</a>
                            </RegularText>
                        </HStack>
                        </HStack>
                    </VStack>
                    </Stack>
                </Box>
            </Box>


            <Box bg="#191919" borderTop="1px solid #272729" borderTopRadius='0' borderBottomRadius='lg' h="auto" ml={['1rem', '2rem', '10rem']} mr={['1rem', '2rem', '10rem']} mb='2rem' pl={['1rem', '2rem', '4rem']} pr={['1rem', '2rem', '4rem']} pt='2rem' pb='4rem' boxShadow='md'>
                <Box>






                <Box display={{ base: 'block', md: 'flex' }} flexDirection={{ base: 'column', md: 'row' }} align='start' gap={4}>
    {Object.entries(sectionControls).map(([section, controls]) => (
        <Box key={section} w={{ base: '100%', md: '50%' }}>
            <HStack sx={{mb:4, mt:8, display: { base: 'block', md: 'flex' }}}
                    spacing={{ base: 0, md: 4 }}>
                <WhiteSubtitleText>{section}</WhiteSubtitleText>
                <Box p={1} w='fit-content' borderRadius='full' borderColor='#272729' borderWidth='1px'>
                    <HStack spacing={2} ml='5px' mr='5px'>
                        <Box w="8px" h="8px" borderRadius="full" style={{ animation: 'flashing 2s infinite' }}></Box>
                        <RegularText>Live scanning</RegularText>
                    </HStack>
                </Box>
            </HStack>
                <Table variant="simple" colorScheme='white' size="sm">
                    <Tbody>
                        {controls.slice(0, 3).map((control, index) => (
                            <Tr key={index} >
                                <Td color="#222222" ml={0} pl={0}>
                                    <VStack spacing={1} align='start'>
                                        <RegularText sx={{color: '#dddddd'}}>
                                            {control}
                                        </RegularText>
                                    </VStack>
                                </Td>
                                <Td isNumeric color="#222222">
                                    <Icon as={CheckCircleIcon} color="#55BF79" boxSize={4} />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
                <Collapse in={show[section]}>

                <Table variant="simple" colorScheme='white' w='100%' ml={0} pl={0} size="sm">
                        <Tbody w='100%'>
                            {controls.slice(3).map((control, index) => (
                                <Tr key={index}  w='100%'>
                                    <Td color="#222222" ml={0} pl={0}>
                                        <VStack spacing={1} align='start'>
                                            <RegularText sx={{color: '#dddddd'}}>
                                                {control}
                                            </RegularText>
                                        </VStack>
                                    </Td>
                                    <Td isNumeric color="#222222">
                                        <Icon as={CheckCircleIcon} color="#55BF79" boxSize={4} />
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                </Table>
                </Collapse>

            <Button 
                size="sm" 
                variant="link" 
                colorScheme="blue" 
                mt={3}
                onClick={() => setShow({ ...show, [section]: !show[section] })}
                sx={{ textDecoration: "none", color: AccentColor, fontWeight:'normal' }}
            >
                <RegularText sx={{ color: AccentColor }}>
                    {show[section] ? 'Show less...' : 'Show more...'}
                </RegularText>
            </Button>
        </Box>
    ))}
</Box>






                {Object.entries(legalPolicies).map(([section, controls]) => (
    <Box key={section} w='100%' mt='5rem'>
        <HStack sx={{mb:4, mt:8}}>
            <WhiteSubtitleText>{section}</WhiteSubtitleText>
            <Box p={1} w='fit-content' borderRadius='full' borderColor='#272729' borderWidth='1px'>
                <HStack spacing={2} ml='5px' mr='5px'>
                    <Box w="8px" h="8px" borderRadius="full" style={{ animation: 'flashing 2s infinite' }}></Box>
                    <RegularText>Live scanning</RegularText>
                </HStack>
            </Box>
        </HStack>


        <SimpleGrid columns={{ base: 2, sm: 3, md: 4 }} spacing={2}>
            {controls.slice(0, 4).map((control, index) => (
                <Box key={index} w='100%' borderRadius='xl' border='1px solid #222222' p={1} position="relative">
                    <Box w='100%' borderRadius='lg' bg='#272728' border='1px solid #222222' display="flex" justifyContent="center" alignItems="center" position="relative">
                        <Image src={DocThumbnail} alt="Logo" w='100px' opacity="0.6"/>
                    </Box>
                    <Box p={2}>
                        <RegularText sx={{ color: '#dddddd' }}>
                            {control}
                        </RegularText>
                    </Box>
                    <Icon as={CheckCircleIcon} color="#55BF79" boxSize={4} position="absolute" top="20px" right="20px" />
                </Box>
            ))}
        </SimpleGrid>

        <Collapse in={show[section]}>
            <SimpleGrid columns={{ base: 2, sm: 3, md: 4 }} spacing={2}>
                {controls.slice(4).map((control, index) => (
                <Box key={index} w='100%' borderRadius='xl' border='1px solid #222222' p={1} position="relative">
                <Box w='100%' borderRadius='lg' bg='#272728' border='1px solid #222222' display="flex" justifyContent="center" alignItems="center" position="relative">
                    <Image src={DocThumbnail} alt="Logo" w='100px' opacity="0.6"/>
                </Box>
                <Box p={2}>
                    <RegularText sx={{ color: '#dddddd' }}>
                        {control}
                    </RegularText>
                </Box>
                <Icon as={CheckCircleIcon} color="#55BF79" boxSize={4} position="absolute" top="20px" right="20px" />
            </Box>
                ))}
            </SimpleGrid>
        </Collapse>

        <Button 
            size="sm" 
            variant="link" 
            colorScheme="blue" 
            mt={3}
            onClick={() => setShow({ ...show, [section]: !show[section] })}
            sx={{ textDecoration: "none", color: AccentColor, fontWeight:'normal' }}
        >
            <RegularText sx={{ color: AccentColor }}>
                {show[section] ? 'Show less...' : 'Show more...'}
            </RegularText>
        </Button>
    </Box>
))}




                </Box>
            </Box>
        </Box>
    </>
  );
};

export default ComplianceCompanyHeader;
