import React, { useState, useEffect } from 'react';
import { Box, Text, HStack, SimpleGrid, VStack, Collapse, Button, Icon, Tr, Td, Tbody, Stack, Image, Table } from "@chakra-ui/react";
import { EmailIcon, LinkIcon, CheckCircleIcon } from '@chakra-ui/icons';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import RegularText from '../text/regulartext.js';
import TitleText from '../text/titletext.js';
import WhiteSubtitleText from '../text/whitesubtitletext.js';
import SOC2Ongoing from '../../assets/Badges/SOC2OngoingBadge.png'

import BlandAI from '../../assets/CustomerLogos/Bland.png'
import Automat from '../../assets/CustomerLogos/Automat.png'
import RythmHealth from '../../assets/CustomerLogos/RythmHealth.png'
import CloudCruise from '../../assets/CustomerLogos/CloudCruise.png'
import Grove from '../../assets/CustomerLogos/Grove.png'
import Toma from '../../assets/CustomerLogos/Toma.png'
import CureAI from '../../assets/CustomerLogos/CureAI.png'
import DocThumbnail from '../../assets/greendocthumbnail.png'
import HIPAA from '../../assets/Badges/HIPAABadge.png'
import SOC2Type1 from '../../assets/Badges/SOC2Type1Badge.png'
import ThumosCare from '../../assets/CustomerLogos/ThumosCare.png'
import Thoughtly from '../../assets/CustomerLogos/Thoughtly.png'
import Fella from '../../assets/CustomerLogos/Fella.png'
import Argovox from '../../assets/CustomerLogos/Argovox.png'
import PrimaryHealth from '../../assets/CustomerLogos/PrimaryHealth.png'
import Twine from '../../assets/CustomerLogos/Twine.png'
import HammingAI from '../../assets/CustomerLogos/HammingAI.png'
import Dart from '../../assets/CustomerLogos/Dart.png'

const ComplianceCompanyHeader = ({ CompanyName, CompanyEmail, AccentColor, ComplianceFrameworks, PrivacyPolicyURL }) => {

//   const sectionControls = {
//     "Infrastructure Security": [
//         {
//             "rule_name": "Network Segmentation Implemented",
//             "rule_description": "Network segmentation is implemented to separate systems and data based on sensitivity and criticality, thereby reducing the risk of unauthorized access and potential breaches."
//         },
//         {
//             "rule_name": "Unique Network System Authentication Enforced",
//             "rule_description": "Unique network system authentication mechanisms are enforced, documented, and managed to ensure that only authorized individuals and systems can access the network infrastructure."
//         },
//         {
//             "rule_name": "Firewall Access Restricted",
//             "rule_description": "Firewall access is restricted to authorized personnel to ensure that only approved individuals can modify firewall settings and rules."
//         },
//         {
//             "rule_name": "Data Transmission Encrypted",
//             "rule_description": "Data transmission is encrypted to protect the confidentiality and integrity of information as it is transmitted between systems."
//         },
//         {
//             "rule_name": "Remote Access Encrypted Enforced",
//             "rule_description": "Remote access to systems and data is encrypted to ensure the confidentiality and integrity of transmitted information."
//         },
//         {
//             "rule_name": "Network Firewalls Utilized",
//             "rule_description": "Network firewalls are implemented, configured, and managed to control and monitor incoming and outgoing network traffic based on predetermined security rules."
//         },
//         {
//             "rule_name": "Intrusion Detection System Utilized",
//             "rule_description": "An intrusion detection system is utilized to monitor and detect unauthorized access or anomalies within the network, ensuring timely identification and response to potential security incidents."
//         },
//         {
//             "rule_name": "Service Infrastructure Maintained",
//             "rule_description": "The service infrastructure is maintained to ensure it remains secure, reliable, and available to support the entity's operations and meet its commitments."
//         },
//         {
//             "rule_name": "Log Management Utilized",
//             "rule_description": "Log management processes are established, documented, and maintained to ensure the proper collection, retention, and analysis of logs for monitoring and auditing purposes."
//         },
//         {
//             "rule_name": "Vulnerability and System Monitoring Procedures Established",
//             "rule_description": "Vulnerability and system monitoring procedures are established, documented, and implemented to detect, assess, and remediate potential threats to the entity's information systems."
//         },
//         {
//             "rule_name": "Infrastructure Performance Monitored",
//             "rule_description": "Infrastructure performance is continuously monitored to ensure the availability, efficiency, and reliability of systems and services."
//         }
//     ],
//     "Organizational Security": [
//         {
//             "rule_name": "Organization Structure Documented",
//             "rule_description": "The organization has established and documented its structure, including roles, responsibilities, and reporting lines."
//         },
//         {
//             "rule_name": "Management Roles and Responsibilities Defined",
//             "rule_description": "Management roles and responsibilities are established, documented, and communicated to ensure accountability and proper execution of tasks related to the entity's information, infrastructure, and software."
//         },
//         {
//             "rule_name": "Employee and Contractor Background Checks Performed",
//             "rule_description": "Background checks are conducted on employees and contractors prior to employment to ensure the integrity and reliability of individuals accessing sensitive information and systems."
//         },
//         {
//             "rule_name": "Confidentiality Agreement Acknowledged by Contractors",
//             "rule_description": "Contractors are required to acknowledge and sign a confidentiality agreement to ensure the protection of sensitive information."
//         },
//         {
//             "rule_name": "Confidentiality Agreement Acknowledged By Employees",
//             "rule_description": "Employees are required to acknowledge and sign a confidentiality agreement to ensure the protection of sensitive information."
//         },
//         {
//             "rule_name": "Code of Conduct Acknowledged by Contractors",
//             "rule_description": "Contractors are required to acknowledge the Code of Conduct at least annually, which is enforced to ensure ethical behavior and compliance with organizational policies."
//         },
//         {
//             "rule_name": "Code of Conduct Acknowledged by Employees and Enforced",
//             "rule_description": "Employees are required to acknowledge the Code of Conduct at least annually, which is enforced to ensure ethical behavior and compliance with organizational policies."
//         },
//         {
//             "rule_name": "Third-Party Agreements Established",
//             "rule_description": "Third-party agreements are established, documented, and reviewed to ensure that third parties comply with the entity's security requirements."
//         },
//         {
//             "rule_name": "Vendor Management Program Established",
//             "rule_description": "A vendor management program is established, documented, and maintained to ensure that third-party service providers meet the entity's security requirements."
//         },
//         {
//             "rule_name": "Risk Management Program Established",
//             "rule_description": "A risk management program is established, documented, and maintained to identify, assess, and respond to risks that could impact the achievement of the entity's objectives."
//         },
//         {
//             "rule_name": "Continuity and Disaster Recovery Plans Established",
//             "rule_description": "Continuity and Disaster Recovery plans are established, documented, and tested to ensure the entity can recover and resume operations in the event of a disruption."
//         },
//         {
//             "rule_name": "Data Classification Policy Established",
//             "rule_description": "A data classification policy is established, documented, and implemented to categorize data based on sensitivity and criticality, ensuring appropriate handling and protection measures are applied."
//         },
//         {
//             "rule_name": "Encryption Key Access Restricted",
//             "rule_description": "Access to encryption keys is restricted to authorized personnel only, ensuring that keys are protected from unauthorized access and misuse."
//         }
//     ],
//     "Product Security": [
//         {
//             "rule_name": "System Changes Externally Communicated",
//             "rule_description": "System changes are documented, approved, and communicated to external stakeholders to ensure they are informed of any modifications that might impact their operations or interactions with the system."
//         },
//         {
//             "rule_name": "Development Lifecycle Established",
//             "rule_description": "A development lifecycle is established, documented, and managed to ensure that system changes are properly controlled, tested, and approved before implementation."
//         },
//         {
//             "rule_name": "Change Management Procedures Enforced",
//             "rule_description": "Change management procedures are established, documented, and enforced to ensure that all changes to systems and applications are properly reviewed, tested, and approved before implementation."
//         },
//         {
//             "rule_name": "Production Deployment Access Restricted",
//             "rule_description": "Access to production deployment is restricted to authorized personnel to ensure the security and integrity of the production environment."
//         },
//         {
//             "rule_name": "Unique Production Database Authentication Enforced",
//             "rule_description": "Unique authentication mechanisms are enforced for accessing the production database to ensure that only authorized individuals can gain access."
//         },
//         {
//             "rule_name": "Production Database Access Restricted",
//             "rule_description": "Access to the production database is restricted to authorized personnel based on their roles and responsibilities, ensuring that only those with a legitimate need can access sensitive data."
//         },
//         {
//             "rule_name": "Production Application Access Restricted",
//             "rule_description": "Access to production applications is restricted to authorized personnel based on their roles and responsibilities, ensuring that only individuals with a legitimate need can access sensitive systems and data."
//         },
//         {
//             "rule_name": "Data Encryption Utilized",
//             "rule_description": "Data encryption is implemented to protect sensitive information both at rest and in transit, ensuring confidentiality and integrity are maintained."
//         },
//         {
//             "rule_name": "Configuration Management System Established",
//             "rule_description": "A configuration management system is established, documented, and maintained to ensure the integrity and security of system configurations and to prevent unauthorized changes."
//         }
//     ],
//     "Internal Security Procedures": [
//         {
//             "rule_name": "Security Policies Established and Reviewed",
//             "rule_description": "Security policies are established, documented, and periodically reviewed to ensure they remain effective and aligned with organizational objectives and regulatory requirements."
//         },
//         {
//             "rule_name": "Incident Response Policies Established",
//             "rule_description": "Incident response policies are established, documented, and communicated to ensure timely and effective response to security incidents."
//         },
//         {
//             "rule_name": "Access Control Procedures Established",
//             "rule_description": "Access control procedures are established, documented, and enforced to ensure that only authorized individuals have access to systems and data."
//         },
//         {
//             "rule_name": "Password Policy Enforced",
//             "rule_description": "A password policy is established, documented, and enforced to ensure the strength and confidentiality of passwords used to access systems and data."
//         },
//         {
//             "rule_name": "Security Awareness Training Implemented",
//             "rule_description": "Security awareness training is implemented, documented, and conducted regularly to ensure that all personnel are aware of security policies, procedures, and their responsibilities in protecting the entity's information and systems."
//         },
//         {
//             "rule_name": "Whistleblower procedure established",
//             "rule_description": "The company has established communication channels that allow employees to securely and anonymously report issues related to fraud, harassment, and other issues impacting the organization's ethical and integrity requirements."
//         },
//         {
//             "rule_name": "Significant changes to people",
//             "rule_description": "Significant changes to people, roles and responsibilities for key personnel are internally communicated to all personnel via an internal communication tool."
//         },
//         {
//             "rule_name": "Access Reviews Conducted",
//             "rule_description": "Access reviews are conducted periodically to ensure that only authorized individuals have access to systems and data, and any discrepancies are promptly addressed."
//         },
//         {
//             "rule_name": "Access Revoked Upon Termination",
//             "rule_description": "Access to the entity's information systems and data is promptly revoked upon termination of an individual's employment or contractual relationship."
//         },
//         {
//             "rule_name": "Anti-Malware Technology Utilized",
//             "rule_description": "Anti-malware technology is implemented, maintained, and regularly updated to protect the entity's information systems, including production servers and workstations, from malicious software."
//         },
//         {
//             "rule_name": "Data Retention Procedures Established",
//             "rule_description": "Data retention procedures are established, documented, and implemented to ensure that data is retained in accordance with regulatory, legal, and business requirements."
//         },
//         {
//             "rule_name": "Incident Management Procedures Followed",
//             "rule_description": "Incident management procedures are established, documented, and followed to identify, respond to, and remediate security incidents in a timely manner."
//         },
//         {
//             "rule_name": "Incident Response Plan Tested",
//             "rule_description": "The incident response plan is tested periodically to ensure its effectiveness and the organization's preparedness to respond to security incidents."
//         }
//     ]
// }


const sectionControls = {
    "Administrative": [
        "Security reviews on 3rd-party tools",
        "Company policies",
        "Disaster recovery plan",
      "Background checks",
      "Employee onboarding checklist",
      "Employee offboarding checklist",
      "Security training",
      "Whistleblower procedure",
      "Performance evaluations",
      "Security task tracker",
      "Incident response plan",
      "Company inventory list",
      "Risk assessment",
      "Tabletop disaster recovery exercise"
    ],
    "Technical": [
      "Encryption in transit and at rest",
      "Logging/monitoring",
      "Database backups",
      "Access request tickets",
      "Segregated development and production environment",
      "Secondary review on code changes",
      "Codebase version control",
      "MFA on accounts",
      "Password manager",
      "Hard drive encryption",
      "Anti-malware settings",
      "Firewalls",
      "Screen lock",
      "Multiple availability zones",
      "Unused root infrastructure account",
      "Infrastructure security scanning"
    ],
  }




const legalPolicies = {
    "Legal policies": [
        "Designation of HIPAA Security Officer Policy",
        "Sanctioning of Employees, Agents and Contractors Policy",
        "Media Re-Use Policy",
        "Information System Activity Review Policy",
        "Workstation Use Policy",
        "Audit Controls Policy",
        "Security Risk Analysis Policy",
        "Security Risk Management Policy",
        "Response and Reporting of Security Incidents Involving PII Policy",
        "Data Backup Plan Policy",
        "Business Continuity and Disaster Recovery Policy",
        "Emergency Access and Emergency Mode Operation Policy",
        "Evaluation of HIPAA Policies",
        "Disposal Policy",
        "Person and Entity Authentication Policy",
        "Transmission Security Policy",
        "Workforce Security Policy",
        "Security Awareness and Training Policy",
        "Integrity Controls Policy",
        "Unique User Identification Policy",
        "Facility Access Controls Policy",
        "Workstation Security Policy",
        "Information Access Management Policy",
        "Compliance with Required and Addressable Standards Policy",
        "Acknowledgment of Receipt and Confidentiality Agreement",
        "Code Of Conduct"
    ],
  }


const [show, setShow] = useState({});

  const companyImages = {
    'Automat': Automat,
    'Bland AI': BlandAI,
    'Rythm Health': RythmHealth,
    'CloudCruise': CloudCruise,
    'Grove': Grove,
    'Toma': Toma,
    'CureAI': CureAI,
    'Thumos Care': ThumosCare,
    'Thoughtly': Thoughtly,
    'Fella': Fella,
    'Argovox': Argovox,
    'Primary Health': PrimaryHealth,
    'Twine': Twine,
    'Hamming AI': HammingAI,
    'Dart': Dart,
  };

  const complianceImages = {
    'HIPAA': HIPAA,
    'SOC2Type1': SOC2Type1,
    // 'SOC2Type2': SOC2Type2,
    'SOC2Ongoing': SOC2Ongoing,
  };

  return (
    <>
        <style>
        {`
            @keyframes flashing {
            0%, 100% { background-color: #63FDAC; opacity: 1; }
            50% { background-color: #63FDAC; opacity: 0; }
            }
        `}
        </style>
        
        <Box bg='#101012' pb='3rem'>
            <Box bg={AccentColor} pt='6rem'>
                <Box bg="#191919" borderTopRadius='lg' borderBottomRadius='0' h="auto" ml={['1rem', '2rem', '10rem']} mr={['1rem', '2rem', '10rem']} pl={['1rem', '2rem', '4rem']} pr={['1rem', '2rem', '4rem']} pt={['2rem', '3rem', '4rem']} pb='2rem' boxShadow='md'>
                    <Stack direction={{ base: 'column', md: 'row' }} align="start">
                    <Image 
                        src={companyImages[CompanyName]}
                        width={{ base: '120px', sm: '150px', md: '200px' }} 
                        height="auto" 
                        mr="3%"
                        alignSelf={{ md: 'flex-start' }}
                        borderRadius="8px"
                        />
                    <VStack align="left">
                    <TitleText fontSize="2xl" color="#353535" fontWeight='600' mb='1rem'>{CompanyName} Compliance Report</TitleText>
                    <Box p={1} w='fit-content' borderRadius='full' borderColor='#272729' borderWidth='1px'>
                        <HStack spacing={2} ml='5px' mr='5px'>
                            <Box w="8px" h="8px" borderRadius="full" style={{ animation: 'flashing 2s infinite' }}></Box>
                            <RegularText>Last updated {new Date().toLocaleDateString()}</RegularText>
                        </HStack>
                    </Box>
                    <RegularText sx={{mb:2, mt:4}}>At {CompanyName}, we actively monitor our security posture to ensure client and company data is protected. We follow compliance standards and industry best practices, which the following page serves to overview.</RegularText>
                        <HStack spacing={5}>
                        {PrivacyPolicyURL && (
                            <HStack>
                            <Icon as={LinkIcon} w={4} h={4} color="#353535" />
                            <RegularText>
                                <a href={PrivacyPolicyURL} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: AccentColor }}>Privacy Policy</a>
                            </RegularText>
                            </HStack>
                        )}
                        <HStack>
                            <Icon as={EmailIcon} w={4} h={4} color="#353535" />
                            <RegularText>
                            <a href={`mailto:${CompanyEmail}`} style={{ textDecoration: "none", color: AccentColor }}>{CompanyEmail}</a>
                            </RegularText>
                        </HStack>
                        </HStack>
                    </VStack>
                    </Stack>
                </Box>
            </Box>


            <Box bg="#191919" borderTop="1px solid #272729" borderTopRadius='0' borderBottomRadius='lg' h="auto" ml={['1rem', '2rem', '10rem']} mr={['1rem', '2rem', '10rem']} mb='2rem' pl={['1rem', '2rem', '4rem']} pr={['1rem', '2rem', '4rem']} pt='4rem' pb='4rem' boxShadow='md'>
                <Box mb='3rem'>
                    <WhiteSubtitleText fontSize="xl" color="#353535" fontWeight='600' mb='0.5rem'>Compliance standards</WhiteSubtitleText>
                    {ComplianceFrameworks.includes("HIPAA") && ComplianceFrameworks.includes("SOC2Type1") && (
                    <RegularText fontSize="sm" color="#8f8f8f" mb='1.5rem'>{CompanyName} adheres to the regulations established by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and has achieved System and Organization Controls 2 (SOC2) Type I compliance. To request a copy of our SOC2 Type I report, please email {CompanyEmail}.</RegularText>
                    )}
                    {ComplianceFrameworks.includes("HIPAA") && !ComplianceFrameworks.includes("SOC2Type1") && (
                    <RegularText fontSize="sm" color="#8f8f8f" mb='1.5rem'>{CompanyName} adheres to the regulations established by the Health Insurance Portability and Accountability Act of 1996 (HIPAA).</RegularText>
                    )}
                    {/* {ComplianceFrameworks.includes("HIPAA") && !ComplianceFrameworks.includes("SOC2Type1") && (
                    <RegularText fontSize="sm" color="#8f8f8f" mb='1.5rem'>{CompanyName} adheres to the regulations established by the Health Insurance Portability and Accountability Act of 1996 (HIPAA).</RegularText>
                    )} */}
                    {!ComplianceFrameworks.includes("HIPAA") && ComplianceFrameworks.includes("SOC2Ongoing") && (
                    <RegularText fontSize="sm" color="#8f8f8f" mb='1.5rem'>{CompanyName} follows security best practices that align with System and Organization Controls 2 (SOC2) Type I compliance.</RegularText>
                    )}
                    <HStack spacing={3} mt={4}>
                        {ComplianceFrameworks.map(framework => (
                            <img key={framework} src={complianceImages[framework]} style={{ width: '90px', height: 'auto' }} alt={`${framework} badge`} />
                        ))}
                    </HStack>
                </Box>
                <Box>






                <Box display={{ base: 'block', md: 'flex' }} flexDirection={{ base: 'column', md: 'row' }} align='start' gap={4}>
    {Object.entries(sectionControls).map(([section, controls]) => (
        <Box key={section} w={{ base: '100%', md: '50%' }}>
            <HStack sx={{mb:4, mt:8, display: { base: 'block', md: 'flex' }}}
                    spacing={{ base: 0, md: 4 }}>
                <WhiteSubtitleText>{section}</WhiteSubtitleText>
                <Box p={1} w='fit-content' borderRadius='full' borderColor='#272729' borderWidth='1px'>
                    <HStack spacing={2} ml='5px' mr='5px'>
                        <Box w="8px" h="8px" borderRadius="full" style={{ animation: 'flashing 2s infinite' }}></Box>
                        <RegularText>Live scanning</RegularText>
                    </HStack>
                </Box>
            </HStack>
                <Table variant="simple" colorScheme='white' size="sm">
                    <Tbody>
                        {controls.slice(0, 3).map((control, index) => (
                            <Tr key={index} >
                                <Td color="#222222" ml={0} pl={0}>
                                    <VStack spacing={1} align='start'>
                                        <RegularText sx={{color: '#dddddd'}}>
                                            {control}
                                        </RegularText>
                                    </VStack>
                                </Td>
                                <Td isNumeric color="#222222">
                                    <Icon as={CheckCircleIcon} color="#55BF79" boxSize={4} />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
                <Collapse in={show[section]}>

                <Table variant="simple" colorScheme='white' w='100%' ml={0} pl={0} size="sm">
                        <Tbody w='100%'>
                            {controls.slice(3).map((control, index) => (
                                <Tr key={index}  w='100%'>
                                    <Td color="#222222" ml={0} pl={0}>
                                        <VStack spacing={1} align='start'>
                                            <RegularText sx={{color: '#dddddd'}}>
                                                {control}
                                            </RegularText>
                                        </VStack>
                                    </Td>
                                    <Td isNumeric color="#222222">
                                        <Icon as={CheckCircleIcon} color="#55BF79" boxSize={4} />
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                </Table>
                </Collapse>

            <Button 
                size="sm" 
                variant="link" 
                colorScheme="blue" 
                mt={3}
                onClick={() => setShow({ ...show, [section]: !show[section] })}
                sx={{ textDecoration: "none", color: AccentColor, fontWeight:'normal' }}
            >
                <RegularText sx={{ color: AccentColor }}>
                    {show[section] ? 'Show less...' : 'Show more...'}
                </RegularText>
            </Button>
        </Box>
    ))}
</Box>






                {Object.entries(legalPolicies).map(([section, controls]) => (
    <Box key={section} w='100%' mt='5rem'>
        <HStack sx={{mb:4, mt:8}}>
            <WhiteSubtitleText>{section}</WhiteSubtitleText>
            <Box p={1} w='fit-content' borderRadius='full' borderColor='#272729' borderWidth='1px'>
                <HStack spacing={2} ml='5px' mr='5px'>
                    <Box w="8px" h="8px" borderRadius="full" style={{ animation: 'flashing 2s infinite' }}></Box>
                    <RegularText>Live scanning</RegularText>
                </HStack>
            </Box>
        </HStack>


        <SimpleGrid columns={{ base: 2, sm: 3, md: 4 }} spacing={2}>
            {controls.slice(0, 4).map((control, index) => (
                <Box key={index} w='100%' borderRadius='xl' border='1px solid #222222' p={1} position="relative">
                    <Box w='100%' borderRadius='lg' bg='#272728' border='1px solid #222222' display="flex" justifyContent="center" alignItems="center" position="relative">
                        <Image src={DocThumbnail} alt="Logo" w='100px' opacity="0.6"/>
                    </Box>
                    <Box p={2}>
                        <RegularText sx={{ color: '#dddddd' }}>
                            {control}
                        </RegularText>
                    </Box>
                    <Icon as={CheckCircleIcon} color="#55BF79" boxSize={4} position="absolute" top="20px" right="20px" />
                </Box>
            ))}
        </SimpleGrid>

        <Collapse in={show[section]}>
            <SimpleGrid columns={{ base: 2, sm: 3, md: 4 }} spacing={2}>
                {controls.slice(4).map((control, index) => (
                <Box key={index} w='100%' borderRadius='xl' border='1px solid #222222' p={1} position="relative">
                <Box w='100%' borderRadius='lg' bg='#272728' border='1px solid #222222' display="flex" justifyContent="center" alignItems="center" position="relative">
                    <Image src={DocThumbnail} alt="Logo" w='100px' opacity="0.6"/>
                </Box>
                <Box p={2}>
                    <RegularText sx={{ color: '#dddddd' }}>
                        {control}
                    </RegularText>
                </Box>
                <Icon as={CheckCircleIcon} color="#55BF79" boxSize={4} position="absolute" top="20px" right="20px" />
            </Box>
                ))}
            </SimpleGrid>
        </Collapse>

        <Button 
            size="sm" 
            variant="link" 
            colorScheme="blue" 
            mt={3}
            onClick={() => setShow({ ...show, [section]: !show[section] })}
            sx={{ textDecoration: "none", color: AccentColor, fontWeight:'normal' }}
        >
            <RegularText sx={{ color: AccentColor }}>
                {show[section] ? 'Show less...' : 'Show more...'}
            </RegularText>
        </Button>
    </Box>
))}




                </Box>
            </Box>
        </Box>
    </>
  );
};

export default ComplianceCompanyHeader;
